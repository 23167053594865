.loading_contanier {
  @apply absolute z-30 flex bg-[rgba(255,255,255,1)] flex-col items-center justify-center w-full h-screen;

  & .image_container {
    @apply flex flex-col w-auto;

    /* & img {
      @apply w-32 h-8 opacity-100;
    } */
  }
}
