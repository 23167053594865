.section_header_nav {
  @apply fixed !z-40 w-full bg-white  flex-row justify-center items-center h-[64px];

  & .header_nav {
    @apply flex mx-auto items-center h-[64px] !z-40;

    & .header_nav_logo {
      @apply flex items-center justify-start md:justify-end w-auto !z-40;

      & img {
        @apply z-40;
      }
    }

    & .header_nav_content {
      @apply flex flex-1 justify-end items-center w-full !z-40;

      & .header_nav_options_container {
        @apply hidden md:flex md:flex-1 !z-30;

        & .ul_nav_container {
          @apply w-full gap-6 flex-col md:flex md:flex-row md:justify-end md:items-center text-xs md:text-sm xl:text-base !text-xana-txt-navbar font-AvanttTrial;
        }
      }

      & .header_nav_button {
        @apply md:z-40 w-7 h-7 md:hidden;
      }
    }
  }
}

.section_header_nav_mobile {
  @apply ease-in-out md:hidden fixed bg-white shadow-md min-h-fit left-0  w-full md:w-auto flex items-center px-5 py-5 md:py-10 !z-10;

  & .ul_nav_mobile_container {
    @apply flex flex-col w-full gap-2 md:flex-row md:items-center !z-10;
  }
}

.li_nav_options {
  @apply w-full flex py-2 text-base cursor-pointer font-medium rounded-md text-xana-txt-navbar hover:bg-xana-blue hover:md:bg-white hover:text-xana-txt-navbar-hover;
}
.li_nav_active {
  @apply text-xana-txt-navbar-hover;
}
