.section_container_system_columns {
  @apply fixed inset-0 z-50 flex items-center justify-center w-full  bg-red-300 opacity-[37%];

  & .container_12_columns {
    @apply grid w-full grid-cols-12 gap-[24px] xl:gap-[32px] px-0;

    & .item_grid {
      @apply flex items-center justify-center col-span-1 bg-red-100 opacity-40 backdrop-blur-sm;
    }
  }
}
