.section_breadcrumb {
  @apply mx-auto text-center w-auto mt-[64px];

  & ol {
    @apply flex justify-center space-x-0 font-AvanttTrial;

    & li {
      @apply flex items-center text-sm font-normal;

      & .arrow {
        @apply mx-[2px] font-semibold text-xana-txt-breadcrumb-arrow;
      }

      & .link_text {
        @apply text-xana-txt-breadcrumb-gray hover:text-xana-txt-breadcrumb-blue;
      }
    }
  }
}
