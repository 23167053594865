.section_footer {
  @apply flex w-full mt-10 py-3 md:py-6 shadow-md bg-white;

  & .footer_container {
    @apply flex flex-col items-start justify-between space-y-5 md:space-y-5 mx-auto;

    & .footer1_container {
      @apply w-full h-auto pb-10 md:pb-3 border-b border-b-slate-200;

      & img {
        @apply z-40;
      }
      & p {
        @apply mt-2 mb-2 text-base font-normal text-xana-txt-footer;
      }
    }

    & .footer2_container {
      @apply flex flex-col-reverse md:flex-row justify-between items-start md:items-center w-full;

      & .copy_container {
        @apply mt-5 md:mt-0  flex flex-col;

        & p {
          @apply flex-1;
        }
      }

      & .ul_container {
        @apply flex flex-row space-x-3 md:space-x-5 md:mr-5 mt-3 md:mt-0;

        & .li_options {
          @apply block cursor-pointer font-medium w-auto h-[18px] md:h-[18px] lg:h-[20px];

          & .img_options {
            @apply w-[20px] h-[20px] text-xana-icon-gray;
          }
        }
      }
    }
  }
}
